import * as React from 'react'
import { Trans } from 'gatsby-plugin-react-i18next'

import { FontAwesomeIcon, solidIcons } from './icons'
const { faCalendarDays } = solidIcons

const Schedule = () => {
  return (
    <section className="flex flex-col px-4 py-8">
      <div className="flex flex-col sm:container sm:mx-auto">
        <div className="mb-8 text-center">
          <h2 className="text-center text-xl font-bold text-gray-800">
            <Trans>schedule.title</Trans>
          </h2>
        </div>
        <div className="flex flex-col md:flex-row md:justify-around">
          <div className="mb-8">
            <h3 className="mb-4 text-lg font-bold text-gray-800 md:text-center">
              <Trans>schedule.prayer-meditation-assembly</Trans>
            </h3>
            <div className="mb-1 flex flex-row items-center text-slate-800">
              <div>
                <FontAwesomeIcon
                  icon={faCalendarDays}
                  size="xl"
                  className="mr-2 text-amber-500"
                />
              </div>
              <div className="flex flex-col">
                <span>
                  <Trans>
                    schedule.prayer-meditation-assembly.time-and-date
                  </Trans>
                </span>
                <span>
                  <Trans>schedule.prayer-meditation-assembly.entrance</Trans>
                </span>
              </div>
            </div>
          </div>
          <div className="mb-8">
            <h3 className="mb-4 text-lg font-bold text-gray-800 md:text-center">
              <Trans>schedule.full-assembly</Trans>
            </h3>
            <div className="mb-1 flex flex-row items-center text-slate-800">
              <div>
                <FontAwesomeIcon
                  icon={faCalendarDays}
                  size="xl"
                  className="mr-2 text-amber-500"
                />
              </div>
              <div className="flex flex-col">
                <span>
                  <Trans>schedule.full-assembly.time-and-date</Trans>
                </span>
                <span>
                  <Trans>schedule.full-assembly.entrance</Trans>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export { Schedule }
