import * as React from 'react'
import { Link } from 'gatsby'
import { Trans } from 'gatsby-plugin-react-i18next'

import { FontAwesomeIcon, solidIcons } from './icons'
const { faPeopleGroup, faBookBible, faClockRotateLeft, faArrowRight } =
  solidIcons

const BasicInfo = () => {
  return (
    <section className="flex flex-col px-4 py-8">
      <div className="sm:container sm:mx-auto">
        <div className="mb-12">
          <h2 className="text-center text-xl font-bold text-gray-800">
            <Trans>basic.new-here</Trans>
          </h2>
        </div>
        <div className="flex flex-col md:flex-row md:justify-between">
          <div className="mb-12 md:w-1/3">
            <div className="pb-6 md:text-center">
              <span className="rounded-2xl bg-amber-500 p-4">
                <FontAwesomeIcon
                  icon={faPeopleGroup}
                  size="lg"
                  inverse
                  className="align-middle"
                />
              </span>
            </div>
            <h3 className="mb-4 text-lg font-bold text-gray-800 md:text-center">
              <Trans>basic.about-us</Trans>
            </h3>
            <p className="mb-1 text-slate-800">
              <Trans>basic.about-us.content</Trans>
            </p>
            <Link to="/about">
              <span className="mr-2 text-amber-500">
                <Trans>basic.read-more</Trans>
              </span>
              <FontAwesomeIcon icon={faArrowRight} className="text-amber-500" />
            </Link>
          </div>
          <div className="mb-12 md:w-1/3 md:px-2">
            <div className="pb-6 md:text-center">
              <span className="rounded-2xl bg-amber-500 p-4">
                <FontAwesomeIcon
                  icon={faBookBible}
                  size="xl"
                  inverse
                  className="align-middle"
                />
              </span>
            </div>
            <h3 className="mb-4 text-lg font-bold text-gray-800 md:text-center">
              <Trans>basic.what-we-believe</Trans>
            </h3>
            <p className="mb-1 text-slate-800">
              <Trans>basic.what-we-believe.content</Trans>
            </p>
            <Link to="/beliefs">
              <span className="mr-2 text-amber-500">
                <Trans>basic.read-more</Trans>
              </span>
              <FontAwesomeIcon icon={faArrowRight} className="text-amber-500" />
            </Link>
          </div>
          <div className="md:mb-12 md:w-1/3">
            <div className="pb-6 md:text-center">
              <span className="rounded-2xl bg-amber-500 p-4">
                <FontAwesomeIcon
                  icon={faClockRotateLeft}
                  size="xl"
                  inverse
                  className="align-middle"
                />
              </span>
            </div>
            <h3 className="mb-4 text-lg font-bold text-gray-800 md:text-center">
              <Trans>basic.our-history</Trans>
            </h3>
            <p className="mb-1 text-slate-800">
              <Trans>basic.our-history.content</Trans>
            </p>
            <Link to="/history">
              <span className="mr-2 text-amber-500">
                <Trans>basic.read-more</Trans>
              </span>
              <FontAwesomeIcon icon={faArrowRight} className="text-amber-500" />
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export { BasicInfo }
