import { graphql, useStaticQuery } from 'gatsby'

export type Metadata = {
  title: string
  description: string
  image: string
  siteUrl: string
}

export const useSiteMetadata = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          image
          siteUrl
        }
      }
    }
  `)

  return data.site.siteMetadata as Metadata
}
