import * as React from 'react'

import { SplashImage } from './splash'
import { BasicInfo } from './basicInfo'
import { Schedule } from './schedule'

const Home = () => {
  return (
    <div className="flex min-h-screen flex-col">
      <SplashImage />
      <BasicInfo />
      <Schedule />
    </div>
  )
}

export { Home }
