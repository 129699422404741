import * as React from 'react'

import { useSiteMetadata, type Metadata } from '../hooks/useSiteMetadata'

type Props = {
  title?: string
}

const Seo: React.FunctionComponent<Props> = ({ title }) => {
  const data: Metadata = useSiteMetadata()

  const seo = {
    ...data,
    title: title ? `${title} | ${data.title}` : data.title,
  }

  return (
    <>
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
    </>
  )
}

export default Seo
