import * as React from 'react'
import { type HeadFC, graphql } from 'gatsby'

import Layout from '../components/layout'
import Seo from '../components/seo'
import { Home } from '../components/home'

const IndexPage = () => {
  return (
    <Layout>
      <Home />
    </Layout>
  )
}

export default IndexPage

export const Head: HeadFC = () => <Seo />

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
