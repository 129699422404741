import * as React from 'react'
import styled from '@emotion/styled'
import { graphql, useStaticQuery } from 'gatsby'
import { Trans } from 'gatsby-plugin-react-i18next'
import BackgroundImage from 'gatsby-background-image'

const SplashImage = () => {
  const data = useStaticQuery(
    graphql`
      query {
        mobileImage: file(relativePath: { eq: "backgroundHome.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 768, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
        desktopImage: file(relativePath: { eq: "backgroundHome.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp
            }
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    `
  )
  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: '(min-width: 768px)',
    },
  ]

  return (
    <StyledBackgroundImage
      Tag="section"
      fluid={sources}
      role="img"
      aria-label="background-image"
      className="flex-1 bg-transparent bg-cover bg-center bg-no-repeat"
    >
      <div className="absolute flex h-full w-full flex-col justify-center text-slate-100">
        <div className="container mx-auto px-4 sm:max-w-xl sm:px-8">
          <h1 className="mb-8 text-center text-3xl font-bold sm:text-5xl">
            <Trans>splash.title</Trans>
          </h1>
          <p className="text-center sm:text-xl">
            <Trans>splash.paragraph</Trans>
          </p>
        </div>
      </div>
    </StyledBackgroundImage>
  )
}

const StyledBackgroundImage = styled(BackgroundImage)`
  min-height: 24rem;

  @media screen and (min-width: 640px) {
    min-height: 32rem;
  }
`

export { SplashImage }
